<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('general.rolesEtpermissions') }}</h2>
    </div>
    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />
        <el-button class="filter-item" style="margin-left: 10px;" type="success" icon="el-icon-plus" @click="handleCreate">
          {{ $t('roles.NewRole') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="ID" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.index }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('roles.nom')" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.name | uppercaseFirst }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('roles.users')" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.userscount | enDeuxChiffres }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('roles.permissions')" min-width="300px">
          <template slot-scope="scope">
            <div v-if="scope.row.name !== 'Admin'">
              <el-tag
                v-for="(UserPermissions, index) in scope.row.permissions.slice(0, 4)"
                :key="index"
                :type="getPermissionColor(UserPermissions.name)"
                style="margin:5px;"
              >{{ UserPermissions.name | uppercaseFirst }}</el-tag>
              <el-button v-if="scope.row.permissions.length>5" size="mini" icon="el-icon-more" style="margin-left:10px;margin-top:10px;" />
            </div>
            <el-tag v-if="scope.row.name === 'Admin'" type="success">{{ $t('general.allpermissions') }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['manage permission'])" align="center" label="Actions" width="150">
          <template slot-scope="scope">
            <!--el-button v-if="scope.row.name !== 'Admin'" v-permission="['manage permission']" type="primary" size="small" icon="el-icon-edit" @click="handleEditPermissions(scope.row.id);">
              {{ $t('permission.editPermissions') }}
            </el-button-->
            <el-button v-if="scope.row.name != 'Admin' && checkPermission(['manage permission'])" type="primary" size="small" icon="el-icon-edit" @click="handleEditPermissions(scope.row.id);" />
            <el-button v-if="scope.row.name != 'Admin' && checkPermission(['manage permission'])" type="danger" size="small" icon="el-icon-delete" @click="handleDeleteRole(scope.row.id, scope.row.name)" />
            <!--el-popconfirm
              :confirm-button-text="$t('general.deleteConfirmButtonText')"
              :cancel-button-text="$t('general.deleteCancelButtonText')"
              icon="el-icon-info"
              icon-color="red"
              :title="$t('general.deleteWarningText')"
              @confirm="handleDeleteRole(scope.row.id);"
            >
            </el-popconfirm-->

          </template>
        </el-table-column>

      </el-table>
    </el-card>

    <!-- ======= CREER PERMISSION DRAWER ======= -->
    <el-drawer
      ref="RoleCreationDrawer"
      :title="editing ? $t('roles.edit') + ' ' + currentRole.name : $t('roles.NewRole')"
      :visible.sync="dialogCreateRole"
      :before-close="handleCloseRoleCreateDraw"
      :loading="createLoading"
      size="40%"
    >
      <div class="drawer-body">
        <div class="rolename content-inputs">
          <el-form ref="roleForm" :rules="rules" :model="newRole" label-position="left">
            <el-form-item :label="$t('roles.nom')" prop="name">
              <el-input v-model="newRole.name" prefix-icon="el-icon-key" />
            </el-form-item>
          </el-form>
          <el-form label-width="80px" label-position="top">
            <el-form-item :label="$t('permission.addedPermissions')">
              <div v-if="isEmptyArray(checkedPermissionsId)" class="no-selection">
                <el-empty :description="$t('permission.noSelectionAdd')" />
              </div>
              <el-tag
                v-for="(pID, index) in checkedPermissionsId"
                :key="index"
                closable
                :type="getSelectedPermissionName(pID).type"
                style="margin:5px;"
                @close="unCheckPermission(pID)"
              >
                {{ getSelectedPermissionName(pID).name | uppercaseFirst }}
              </el-tag>
            </el-form-item>
          </el-form>
        </div>
        <div class="permissionscontainer">
          <el-card class="box-card">
            <el-form :model="currentRole" label-width="80px" label-position="top">
              <div class="row search-input">
                <el-row :gutter="20">
                  <el-col :span="18">
                    <el-select v-model="selectedPermissionNames" multiple filterable placeholder="Sélectionner" @change="showOnlySelected">
                      <el-option
                        v-for="(permissionName, index) in normalizedPermissionNames"
                        :key="index"
                        :label="permissionName"
                        :value="permissionName"
                        style="width:100%"
                      />
                    </el-select>
                  </el-col>
                  <el-col :span="6">
                    <el-button type="primary" icon="el-icon-plus" circle @click="dialogCreatePermission=true" />
                  </el-col>
                </el-row>
              </div>
              <el-collapse v-model="activeName" accordion>
                <el-collapse-item v-for="(normalizedPermission,index) in normalizedPermissions" :key="index" :title="normalizedPermission.name" :name="index">
                  <!--el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllPermissionsChange(normalizedPermission.permissionsIds)">Sélectionner tout</el-checkbox>
                  <div style="margin: 15px 0;" /-->
                  <el-checkbox-group v-model="checkedPermissionsId">
                    <el-checkbox v-for="(thePermission,key) in normalizedPermission.permissions" :key="key" :label="thePermission.id" :class="thePermission.name.toLowerCase()+'-pane'" @change="handleCheckedPermissionChange(thePermission.name.toLowerCase(), normalizedPermission.permissionsIds)">{{ thePermission.name | uppercaseFirst }}</el-checkbox>
                  </el-checkbox-group>
                </el-collapse-item>
              </el-collapse>
            </el-form>
          </el-card>
          <div class="clear-left" />
        </div>
      </div>
      <div class="el-drawer__footer">
        <div style="text-align:right;">
          <el-button plain type="danger" @click="cancelRoleForm">
            {{ $t('permission.cancel') }}
          </el-button>
          <el-button type="primary" :loading="roleCreating" @click="editing ? updateRole() : createRole()">
            {{ createLoading ? $t('general.saving') : $t('general.save') }}
          </el-button>
        </div>
      </div>
      <!-- ======= GESTION PERMISSIONS ======= -->
      <el-drawer
        :title="$t('permission.manage')"
        :append-to-body="true"
        :visible.sync="dialogCreatePermission"
      >
        <div class="container">
          <div class="newpermission-input">
            <el-card class="box-card">
              <el-form ref="permissionForm" :rules="permissionsRules" :model="newPermission" label-position="left" label-width="40%">
                <el-form-item :label="$t('permission.name')" prop="name">
                  <el-input v-model="newPermission.name" prefix-icon="el-icon-key" />
                </el-form-item>
                <el-checkbox v-model="checkAllPermissionPrefixs" :indeterminate="isPermissionIndeterminate" :disabled="checkTakenPermissionName(newPermission.name)" @change="handleCheckAllPermissionPrefixsChange">Sélectionner tout</el-checkbox>
                <div style="margin: 15px 0;" />
                <el-checkbox-group v-model="checkedPermissionPrefixs" @change="handleCheckedPermissionPrefixsChange">
                  <el-checkbox v-for="permissionPrefix in permissionPrefixs" :key="permissionPrefix" :class="permissionPrefix.toLowerCase()+'-pane'" :label="permissionPrefix" :disabled="checkTakenPermission(permissionPrefix+' '+newPermission.name)">{{ permissionPrefix | uppercaseFirst }}</el-checkbox>
                </el-checkbox-group>
              </el-form>
              <div class="validation-btn">
                <el-button plain type="danger" @click="dialogCreatePermission=false">
                  {{ $t('permission.cancel') }}
                </el-button>
                <el-button type="primary" :loading="permissionCreating" @click="createPermissions()">
                  {{ permissionCreating ? $t('general.saving') : $t('general.save') }}
                </el-button>
              </div>
            </el-card>
            <div class="permissions-liste">
              <el-table v-loading="permissionTableLoading" :data="permissions" border fit highlight-current-row style="width: 100%">
                <el-table-column align="center" :label="$t('roles.nom')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.name | uppercaseFirst }}</span>
                  </template>
                </el-table-column>
                <el-table-column v-if="checkPermission(['manage permission'])" align="center" label="Actions" width="80">
                  <template slot-scope="scope">
                    <el-popconfirm
                      :confirm-button-text="$t('general.deleteConfirmButtonText')"
                      :cancel-button-text="$t('general.deleteCancelButtonText')"
                      icon="el-icon-info"
                      icon-color="red"
                      :title="$t('general.deleteWarningText')"
                      @confirm="handleDeletePermission(scope.row.id);"
                    >
                      <el-button v-if="checkPermission(['manage permission'])" slot="reference" type="danger" size="mini" icon="el-icon-delete" />
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-drawer>
      <!-- ======= FIN GESTION PERMISSION ======= -->

    </el-drawer>
    <!-- ======= FIN CREER PERMISSION DRAWER ======= -->
  </div>
</template>

<script>
import Resource from '@/api/resource';
import RoleResource from '@/api/role';
import waves from '@/directive/waves'; // Waves directive
import permission from '@/directive/permission'; // Permission directive (v-permission)
import checkPermission from '@/utils/permission'; // Permission checking
import checkRole from '@/utils/role'; // Vérification Roles
const roleResource = new RoleResource();
const permissionResource = new Resource('permissions');
const _permissionPrefixs = ['manage', 'create', 'edit', 'delete', 'activate', 'hide', 'list'];
export default {
  name: 'RoleList',
  directives: { waves, permission },
  data() {
    return {
      // CRUD PERMISSION
      permissionEditing: false,
      permissionCreating: false,
      checkAllPermissionPrefixs: false,
      checkedPermissionPrefixs: [],
      isPermissionIndeterminate: false,
      permissionPrefixs: _permissionPrefixs,
      newPermission: {},
      permissionLabels: [],
      permissionTableLoading: false,
      // FIN CRUD PERMISSION
      dialogCreatePermission: false,
      editing: false,
      checkAll: false,
      isIndeterminate: true,
      activeName: '',
      currentRoleId: 1,
      list: [],
      loading: true,
      saveLoading: false,
      dialogVisible: false,
      permissions: [],
      checkedPermissionsId: [],
      filtredPermissions: [],
      normalizedPermissionNames: [],
      normalizedPermissions: [],
      allnormalizedPermissions: [],
      selectedPermissionNames: [],
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      roleCreating: false,
      newRole: {},
      roleDialogFormVisible: false,
      rules: {
        name: [{ required: true, message: this.$t('roles.NameRequired'), trigger: 'blur' }],
      },
      permissionsRules: {
        name: [{ required: true, message: this.$t('permission.NameRequired'), trigger: 'blur' }],
      },
      createLoading: false,
      dialogCreateRole: false,
    };
  },
  computed: {
    currentRole: function() {
      const found = this.list.find(role => role.id === this.currentRoleId);
      if (found === undefined) {
        return { name: '', permissions: [] };
      }

      return found;
    },
  },
  watch: {
    filterText(val) {
      this.$refs.managePermissions.filter(val);
    },
  },
  created() {
    this.getRoles();
    this.getPermissions();
  },

  methods: {
    checkPermission,
    checkRole,
    handleCheckAllPermissionPrefixsChange(val) {
      this.checkedPermissionPrefixs = val ? _permissionPrefixs : [];
      this.isPermissionIndeterminate = false;
    },
    handleCheckedPermissionPrefixsChange(value) {
      const checkedCount = value.length;
      this.checkAllPermissionPrefixs = checkedCount === this.permissionPrefixs.length;
      this.isPermissionIndeterminate = checkedCount > 0 && checkedCount < this.permissionPrefixs.length;
    },
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    unCheckPermission(id){
      const index = this.checkedPermissionsId.indexOf(id);
      this.checkedPermissionsId.splice(index, 1);
    },
    handleCheckAllPermissionsChange(currentPermissions) {
      if (currentPermissions.every((val) => this.checkedPermissionsId.includes(val))){
        this.checkedPermissionsId = this.checkedPermissionsId.concat(currentPermissions);
      } else {
        this.checkedPermissionsId = this.checkedPermissionsId.filter((el) => !currentPermissions.includes(el));
      }
      this.isIndeterminate = false;
    },
    handleCheckedPermissionChange(type, currentPermissions) {
      if (type === 'manage'){
        if (!currentPermissions.every((val) => this.checkedPermissionsId.includes(val))){
          this.checkedPermissionsId = [...new Set([...this.checkedPermissionsId, ...currentPermissions])];
        } else {
          this.checkedPermissionsId = this.checkedPermissionsId.filter((el) => !currentPermissions.includes(el));
        }
      }
    },
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.name.indexOf(value) !== -1;
    },
    handleCreate() {
      this.resetNewRole();
      this.normalizedPermissions = this.allnormalizedPermissions;
      this.selectedPermissionNames = [];
      this.checkedPermissionsId = [];
      this.dialogCreateRole = true;
      this.$nextTick(() => {
        this.$refs['roleForm'].clearValidate();
      });
    },
    handleCloseRoleDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleCloseRoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelRoleForm() {
      this.saveLoading = false;
      this.$refs.RoleCreationDrawer.closeDrawer();
    },
    resetNewRole() {
      this.editing = false;
      this.newRole = {
        name: '',
      };
    },
    handleFilter() {
      this.query.page = 1;
      this.getRoles();
    },
    async createPermissions() {
      await this.createPerm();
      await this.getPermissions();
      this.dialogCreatePermission = false;
    },
    async createPerm() {
      this.$refs['permissionForm'].validate((valid) => {
        if (valid) {
          this.permissionCreating = true;
          const fullNames = [];
          this.checkedPermissionPrefixs.forEach(pref => {
            const permFullName = pref + ' ' + this.newPermission.name;
            fullNames.push({ name: permFullName });
          });
          fullNames.forEach(permiss => {
            permissionResource
              .store(permiss)
              .then(async(response) => {
                this.$message({
                  message: this.$t('permission.iscreatedSuccessfully') + ' (' + permiss.name + ')',
                  type: 'success',
                  duration: 5 * 1000,
                });
              })
              .catch(error => {
                console.log(error);
              }).finally(() => {
                this.permissionCreating = false;
              });
          });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    async createRole() {
      this.$refs['roleForm'].validate((valid) => {
        if (valid) {
          this.roleCreating = true;
          roleResource
            .store(this.newRole)
            .then(async(response) => {
              this.$message({
                message: this.$t('roles.NewRole') + ' ' + this.newRole.name + ' ' + this.$t('roles.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentRoleId = response.data.id;
              await roleResource.update(this.currentRoleId, { permissions: this.checkedPermissionsId });
              this.resetNewRole();
              await this.handleFilter();
              this.dialogCreateRole = false;
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.roleCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    updateRole() {
      this.$refs['roleForm'].validate((valid) => {
        if (valid) {
          this.roleCreating = true;
          this.saveLoading = true;
          roleResource.update(this.currentRoleId, { name: this.newRole.name, permissions: this.checkedPermissionsId }).then(response => {
            this.saveLoading = false;
            this.dialogVisible = false;
            this.editing = false;
            this.resetNewRole();
            this.$message({
              message: this.$t('roles.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateRole = false;
            this.handleFilter();
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.roleCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    async getRoles() {
      this.loading = true;
      const { data } = await roleResource.list(this.query);
      this.list = data;
      this.list.forEach((role, index) => {
        role['index'] = index + 1;
        role['description'] = this.$t('roles.description.' + role.name);
      });
      this.loading = false;
    },

    getPermissionColor(permissionName){
      if (permissionName.startsWith('create')) {
        return 'primary';
      } else if (permissionName.startsWith('edit')) {
        return 'warning';
      } else if (permissionName.startsWith('delete')) {
        return 'danger';
      } else if (permissionName.startsWith('manage')) {
        return 'success';
      } else if (permissionName.startsWith('activate')) {
        return 'primary';
      } else if (permissionName.startsWith('hide')) {
        return 'info';
      } else {
        return 'primary';
      }
    },
    getSelectedPermissionName(permissionID){
      const foundPermission = this.permissions.find(permission => permission.id === permissionID);
      return { name: foundPermission.name, type: this.getPermissionColor(foundPermission.name) };
    },
    showOnlySelected(){
      const selected = [];
      this.selectedPermissionNames.forEach(permissionName => {
        const foundPermission = this.allnormalizedPermissions.find(permission => permission.name === permissionName);
        selected.push(foundPermission);
      });
      if (!this.isEmptyArray(selected)) {
        this.normalizedPermissions = selected;
      } else {
        this.normalizedPermissions = this.allnormalizedPermissions;
      }
    },
    async getPermissions() {
      var dejaGerer = false;
      this.normalizedPermissions = [];
      this.permissionLabels = [];
      const { data } = await permissionResource.list({});
      const all = data;
      this.permissions = all;
      all.forEach(permission => {
        const normalizedName = this.normalizeLabel(permission.name);
        if (normalizedName != null && !this.normalizedPermissionNames.includes(normalizedName)){
          this.normalizedPermissionNames.push(normalizedName);
        }
        this.permissionLabels.push(permission.name);
      });

      this.normalizedPermissionNames.forEach(permissionName => {
        var element = { name: permissionName, permissions: [], permissionsIds: [] };
        all.forEach(permission => {
          if (permission.name.toLowerCase().includes(permissionName.toLowerCase())) {
            dejaGerer = permission.name.toLowerCase().includes('manage');
            element.name = permissionName;
            if (!dejaGerer) {
              element.permissions.push({ id: permission.id, name: permission.name.toLowerCase().replace(' ' + permissionName.toLowerCase(), '') });
              element.permissionsIds.push(permission.id);
            } else {
              element.permissions.unshift({ id: permission.id, name: permission.name.toLowerCase().replace(' ' + permissionName.toLowerCase(), '') });
              element.permissionsIds.unshift(permission.id);
            }
          }
        });
        this.normalizedPermissions.push(element);
        this.allnormalizedPermissions = this.normalizedPermissions;
      });
    },
    checkTakenPermission(name){
      return this.permissionLabels.includes(name);
    },
    checkTakenPermissionName(name){
      if (name == null) {
        return true;
      }
      return this.normalizedPermissionNames.includes(this.$options.filters.uppercaseFirst(name.toLowerCase()));
    },
    normalizeLabel(name) {
      var normalizedName;
      if (name.startsWith('create')) {
        normalizedName = name.substring(7);
      } else if (name.startsWith('edit')) {
        normalizedName = name.substring(5);
      } else if (name.startsWith('delete')) {
        normalizedName = name.substring(7);
      } else if (name.startsWith('manage')) {
        normalizedName = name.substring(7);
      } else if (name.startsWith('activate')) {
        normalizedName = name.substring(9);
      } else if (name.startsWith('hide')) {
        normalizedName = name.substring(5);
      } else if (name.startsWith('list')) {
        normalizedName = name.substring(5);
      } else {
        normalizedName = null;
      }
      return this.$options.filters.uppercaseFirst(normalizedName);
    },
    permissionsStandard(permissions) {
      const permissionsStandards = [];
      permissions.forEach(permission => {
        const permissionName = permission.name;
        if (permissionName.startsWith('create') || permissionName.startsWith('edit') || permissionName.startsWith('delete') || permissionName.startsWith('manage') || permissionName.startsWith('activate') || permissionName.startsWith('hide') || permissionName.startsWith('list')) {
          permissionsStandards.push(permission);
        }
      });
      return permissionsStandards;
    },

    handleDeleteRole(id, name) {
      this.$confirm(this.$t('roles.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        this.$swal({
          title: this.$t('general.SureQuestion'),
          text: this.$t('general.irrversibleMessage'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('general.ouiSupprimer'),
        }).then((result) => {
          if (result.isConfirmed) {
            roleResource.destroy(id).then(response => {
              this.$message({
                type: 'success',
                message: this.$t('roles.suppressionEffectue'),
              });
              this.handleFilter();
            }).catch(error => {
              console.log(error);
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },

    handleDeletePermission(id) {
      this.permissionTableLoading = true;
      permissionResource.destroy(id).then(response => {
        this.$message({
          type: 'success',
          message: this.$t('permission.suppressionEffectue'),
        });
        this.getPermissions();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.permissionTableLoading = false;
      });
    },

    handleEditPermissions(id) {
      this.editing = true;
      const rolePermissionsID = [];
      const found = this.list.find(role => role.id === id);
      const normalizedNames = [];
      found.permissions.forEach(permission => {
        rolePermissionsID.push(permission.id);
        const normalizedName = this.normalizeLabel(permission.name);
        if (normalizedName != null && !normalizedNames.includes(normalizedName)){
          normalizedNames.push(normalizedName);
        }
      });
      this.newRole = found;
      this.normalizedPermissions = this.allnormalizedPermissions;
      this.selectedPermissionNames = normalizedNames;
      this.checkedPermissionsId = rolePermissionsID;
      this.showOnlySelected();
      this.dialogCreateRole = true;
      this.currentRoleId = id;
    },

    savePermission() {
      if (this.saveLoading) {
        return;
      }
      this.saveLoading = true;
      roleResource.update(this.currentRoleId, { name: this.newRole.name, permissions: this.checkedPermissionsId }).then(response => {
        this.saveLoading = false;
        this.dialogVisible = false;
        this.resetNewRole();
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.permissions-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
